<template>
  <div class="choose-box">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">
        选择{{
          poiType === '5'
            ? '装车'
            : poiType === '6'
            ? '停车'
            : poiType === '7'
            ? '发车'
            : poiType === '8'
            ? '卸车'
            : '-'
        }}点
      </div>
    </div>

    <!-- 内容 -->
    <PullDownRefresh @refresh="refresh" v-if="dataList.length > 0 && loaded">
      <div class="content">
        <div
          v-for="(item, index) in dataList"
          :class="item.checkFlag ? 'list-card list-card-active' : 'list-card'"
          :key="index"
          @click="handleCheck(index)"
        >
          <p>{{ item.poiName }}</p>
          <img
            v-if="item.checkFlag"
            class="img-right"
            src="../../assets/parking/right.png"
          />
        </div>
      </div>
    </PullDownRefresh>

    <!-- 空数据 -->
    <div class="empty" v-else-if="dataList.length <= 0 && loaded">
      <img src="../../assets/parking/empty.png" alt="" />
      <p>暂无数据</p>
    </div>

    <van-button
      :class="disabledBtn ? 'btn btn-active' : 'btn'"
      :disabled="!disabledBtn"
      @click="handleSure()"
      >确定挪车</van-button
    >
  </div>
</template>

<script>
import { getPoiListByType, infieldMoveMissionDispatch } from '@/api/apiv2';
import { Toast } from 'vant';

import PullDownRefresh from '@/components/PullDownRefresh';

export default {
  name: 'choosePoint',
  components: {
    PullDownRefresh,
  },
  data() {
    return {
      poiType: undefined,
      parkCode: undefined,
      dataList: [],
      disabledBtn: false,
      stationId: '',
      saveIndex: '',
      loaded: false,
    };
  },

  mounted() {
    this.getList();
    this.poiType = this.$route.query.poiType;
    this.parkCode = this.$route.query.parkCode;
    this.vin = this.$route.query.vin;
    this.userId = this.$route.query.userId;
  },
  methods: {
    goback() {
      window.location.href = `./onSiteParking?parkCode=${this.parkCode}&userId=${this.userId}&vin=${this.vin}`;
    },

    refresh(done) {
      this.getList(done);
    },

    // 点击每一行
    handleCheck(index) {
      this.disabledBtn = true;
      let arr = [...this.dataList];
      arr.map((item, ind) => {
        if (index === ind) {
          this.stationId = item.id;
          this.saveIndex = index;
          item['checkFlag'] = true;
        } else {
          item['checkFlag'] = false;
        }
      });
      this.dataList = arr;
    },

    //获取列表
    getList(done) {
      this.$loadingCircle.start();
      getPoiListByType({
        parkCode: this.$route.query.parkCode,
        poiType: this.$route.query.poiType,
      })
        .then((res) => {
          this.loaded = true;
          done && done();
          res.map((item, index) => {
            if (done && this.saveIndex === index) {
              item['checkFlag'] = true;
              return;
            }
            item['checkFlag'] = false;
          });
          this.dataList = res;
        })
        .finally(() => {
          this.loaded = true;
          this.$loadingCircle.end();
        });
    },

    // 点击确定挪车
    handleSure() {
      Toast.loading({
        forbidClick: true,
        duration: 2000,
        className: 'response-toast-loading',
      });
      this.disabledBtn = false;

      this.stationId &&
        infieldMoveMissionDispatch({
          vin: this.vin,
          userId: this.userId,
          stationId: this.stationId,
        }).then((res) => {
          this.stationId = '';
          this.getList();
          if (res.code === '10000') {
            Toast({
              message: '挪车指令已发送',
              className: 'response-toast',
              forbidClick: true,
              duration: 3000,
            });
            setTimeout(() => {
              window.location.href = 'neolix://parking_trim';
            }, 3000);
          } else {
            Toast({
              message: res.msg,
              className: 'response-toast',
              forbidClick: true,
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.pullRefresh {
  height: auto !important;

  .present-box {
    height: 100%;
  }
}
.choose-box {
  width: 100%;
  height: 100%;
  background: rgba(245, 246, 249, 1);
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;
  overflow: scroll;

  .header {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    position: fixed;
    left: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 0.64rem;
      height: 0.64rem;
      margin: 0.08rem 0.22rem;
    }
    .title {
      font-size: 0.3rem;
      color: #000000;
      letter-spacing: 0;
      font-weight: 500;
      text-align: center;
      width: 100%;
      min-width: 2rem;
      margin-right: 1.08rem;
    }
  }

  .empty {
    width: 100%;
    img {
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      margin: 3rem auto 0;
    }
    p {
      font-size: 0.32rem;
      text-align: center;
      font-weight: 400;
    }
  }
  .content {
    width: 100%;
    // height: 100%;
    // overflow: scroll;
    padding: 1.28rem 0.4rem 1.58rem;
    .list-card {
      position: relative;
      width: 6.7rem;
      border-radius: 0.12rem;
      background: rgba(255, 255, 255, 1);
      font-size: 0.32rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 0.24rem;
      border: solid 2px transparent;
      padding: 0.48rem 0.3rem;
      box-sizing: border-box;
      p {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      }
      .img-right {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 0.56rem;
        height: 0.56rem;
        border-top-right-radius: 0.12rem;
      }
    }
    .list-card-active {
      border: 2px solid rgba(67, 110, 255, 1);
    }
  }
  .btn {
    position: fixed;
    left: 50%;
    bottom: 0.48rem;
    transform: translateX(-50%);
    width: 6.66rem;
    height: 0.92rem;
    border-radius: 0.46rem;
    background: rgba(186, 201, 251, 1);
    font-size: 0.32rem;
    text-align: center;
    font-weight: bold;
    line-height: 0.92rem;
    color: rgba(255, 255, 255, 1);
    box-shadow: none;
    opacity: 1;
    border: none;
  }
  .btn-active {
    background: rgba(67, 110, 255, 1);
  }
}
</style>
